<template>
    <div class="row">
        <span class="col-sx-12 col-sm-12 col-md-12 d-sm-block d-md-block d-sx-block d-lg-none questionListBtn" v-if="selectedquestion" @click="openNav()">&#9776; Questions</span>
        <div id="mySidenav" class="questionList col-lg-4 col-xl-3 col-md-12 col-sx-12 col-sm-12" :class="{'sidenav':selectedquestion}">
            <div class="questionList col-sx-12 col-sm-12 col-md-12 d-sm-block d-sx-block d-md-block d-lg-none d-md-none pb-3 pt-3 mobileQuestTitle" >
                <a v-if="selectedquestion" class="closebtn" @click="closeNav()">&times;</a>
            <h4>LISTENING: Multiple Choice (Single)</h4>
            </div>
            <app-IntervalList ref="intervalList" :questionLists="questionLists"/>
        </div>
        <div class="questonhelp col-lg-8 col-xl-9 d-none d-lg-block" v-if="!selectedquestion">
            <h4 class="text-center mb-4 fancy"><span>LISTENING: Multiple Choice (Single)</span></h4>
            <h5 class="mb-1">Introduction</h5>
            <p class="para mb-2">Listen to the recording and answer the multiple-choice question by selecting the correct response. Only one response is correct.</p>
            <h5 class="mb-1">Skills assessed</h5>
            <p class="heading mb-2">Listening</p>
            <h5 class="mb-1">Number of questions</h5>
            <p class="para mb-2"> 1 - 2 </p>
            <template v-if="user_type == 'premium'">
            <h5 class="mb-1">KEY POINTS</h5>
            <ul class="ml-5">
                <li>The audio begins to play automatically. You are only able to listen to the audio recording once.</li>
                <li>To select an option click on it using the left button on your mouse. If you change your mind, left-click on the option again to deselect it, or left-click on a different option.</li>
                <li>In the 5 seconds before the recording begins, quickly read the question and answer options to make sure you understand the topic.</li>
                <li>You will be judged for only listening.</li>
            </ul>
            </template>
            <app-ListeningExtraInfo/>
        </div>
        <div class="questiondesc col-lg-8 col-xl-9 col-md-12 col-sm-12 col-sx-12" :class="{'col-12':(questId != 0)}" v-if="selectedquestion">
            <h4>Multiple Choice (Single)</h4>
            <p class="questHeading">Listen to the recording and answer the single-choice question by selectingthe correct response . Only one response is correct.</p>
            
            <div class="row mb-2">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 titleDiv">
                <div class="prepare" ><p>{{title}} </p><app-Timer ref="timer"/></div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-right pr-0" >
            <app-QuestionStatus ref="QuestionResetStatus" :selectedQuestion="selectedquestion" :question_id="selectedquestion.question_id" :idName="'question_id'" :tableName="'tblListeningSingleChoice'"/>
            </div>
            </div>
            <div v-if="questionrecording" class="audio-container"><audio id="questionRec" :src="questionrecording" controls /></div>
            <p  class="questionMark mt-4" style="padding-bottom: 5px !important;">{{selectedquestion.question_desc}}</p>
            <!-- <p>{{selectedquestion.option_desc}}</p> -->
            <ol class="mt-1">
                <li v-for="(item, i) in answerLists" :key="item.option_id" class="mt-3">
                <label :id="item.option_id">
                    <input type="radio"
                    v-on:change="toggle(item)"
                    v-bind:checked="item.done">

                    <span v-if="item.done">
                    {{alphaList[i]}})  {{ item.option_desc }}
                    </span>
                    <span v-else>
                    {{alphaList[i]}})  {{ item.option_desc }}
                    </span>
                </label>
                </li>
            </ol>

            <div class="row">
                <div class="col-12 text-left mt-4" style="background: #001848;" v-if="answerSubmit">
                    <p style="padding-left:0px!important;color:#fff;"><strong>Your Score:   <span class="pl-1">{{score_count}}</span>/{{actualAnswer.length}}</strong></p>
                </div>
                <div class="col-lg-12 col-xl-6 col-md-12 text-left col-sm-12 questBtnDiv mt-5">
                    <b-button class="mr-2 pl-4 pr-4" variant="outline-info" @click="submitanswer()">Submit</b-button>
                    <b-button variant="outline-warning" class="mr-2 pl-4 pr-4" @click="redoQuest()">Re-do</b-button>
                    <b-button variant="outline-dark" class="mr-2 pl-4 pr-4" @click="showModal()">Dict Mode</b-button>
                    <toggle-button :width="75" :height="28" v-model="checkAnswerFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Answer', unchecked: 'Answer'}"/>
                </div>
                <div class="col-6 text-right mt-3 pl-0 d-none d-xl-block mt-5">
                    <div class="row">
                        <div class="col-2 p-0">
                             <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-3 pr-3">Prev</b-button>
                        </div>
                        <div class="col-8 p-0">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-icon shift-v="1.2" scale="1.3" icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input type="search" placeholder="Question title"></b-form-input>
                                <b-input-group-append>
                                <b-button variant="outline-secondary">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-2 p-0 text-left">
                             <!-- <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-2 prevQuestion pl-3 pr-3">Prev Quest</b-button> -->
                        <b-button @click="$refs.intervalList.changeQuestion('next')" variant="outline-info" class="ml-2 nextQuestion pl-3 pr-3">Next</b-button>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center mt-4" v-if="checkAnswerFlag">
                <h4 class="decorated" style="text-transform:capitalize;"><span>Answer</span></h4>
                    <div class="col-12 mb-2 mt-2 results text-left" >
                    <h5 style="text-transform:capitalize;">Answer:- </h5>
                    <p style="display:block;padding-bottom: 0px !important;">
                        <ul >
                            <li v-for="item in actualAnswer" :key="item.answer_id" class="rightAnswer"><strong> {{item.option_desc}}</strong></li>
                        </ul>
                    </p>
                    <p  class="mt-4" style="padding-bottom: 5px !important;">{{selectedquestion.transcript}}</p>

                    </div>
                </div>
            </div>

            <!-- <div class="row">
                <div class="col-12  mb-4 results" v-if="checkAnswerFlag == true">
                    <h6>Result:- </h6>
                    <p><strong>Your result is {{this.userAnswer.user_score}} out of 1</strong></p>
                </div>
                 <div class="col-12 text-center mt-3" v-if="selectedquestion">
                    <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-5 prevQuestion">Prev Question</b-button>
                    <b-button variant="success" @click="submitanswer()">Find Answer & Marks</b-button>
                    <b-button @click="$refs.intervalList.changeQuestion('next')" class="ml-5 nextQuestion">Next Question</b-button>

                 </div>
            </div> -->
        </div>
        <b-modal ref="search-Result" size="lg" scrollable centered hide-footer title="Search Result" ok-only ok-title="Cancel" ok-variant="secondary">
             <b-container v-if="searchKey!='' | searchQuestionList!=null"><app-IntervalList name="search" :popup="true" :parentFunctionCalling="getQuestionDetail" :questionLists="searchQuestionList" :questionId="'question_id'"/></b-container>
        </b-modal>
        <b-modal id="dictionery-Modal" size="lg" scrollable centered hide-footer hide-header @hidden="hideModal" ok-only ok-title="Cancel" ok-variant="secondary">
         <b-container class="" v-if="selectedquestion!=null">
              <app-dictTemp :templatePara="dictText" :title="modelTitle"/>
         </b-container>
        </b-modal>
        <div class="col-12 mt-5 d-none d-lg-block" v-if="selectedquestion!=null">
            <app-DiscussionTab ref="discussionTab" :question_id="currentQuestion" :tableName="'tblUserListeningSingleAnswer'"/>
        </div> 
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import Timer from '../reuseable/timer'
import $ from "jquery";
import IntervalList from '../reuseable/intervalList'
import QuestionStatus from '../reuseable/questStatus'
import dictTemplate from '../dictionerymode/dictioneryTemplate'
import DiscussionTab from '../reuseable/discussionTab'
import ListeningExtraInfo from '../reuseable/listeningInformation.vue'
//import LocalStorageService from '../../store/LocalStorageService'
export default {
    components:{
        'app-Timer':Timer,
 'app-IntervalList': IntervalList,
 'app-dictTemp': dictTemplate,
 'app-QuestionStatus':QuestionStatus,
 'app-DiscussionTab':DiscussionTab,
 'app-ListeningExtraInfo':ListeningExtraInfo
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            selectedquestion: null,
            answerLists:{
                type: Object,
                required: true
            },
            questionrecording: null,
            title:'Prepare',
            alphaList:['A','B','C','D','E','F','G','H','I',],
            userAnswerResponse:null,
            userAnswer:{
                url:'',
                user_id:0,
                question_id:0,
                option_id:0,
                user_score:'',
                done:false,
                mock_test_id:0
            },
            score_count:0,
            wrong: false,
            checkAnswerFlag: false,
            user_type:'Free',
            currentQuestion:0,
            actualAnswer:null,
            answerSubmit:false,
            searchKey:'',
            searchQuestionList:null,
            isShowModal: false,
            modelTitle:'heading',
            dictText:'',
            currentAnswer:[]
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'listeningmodule/fetchQuestion',
            saveAnswer: 'listeningmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'listeningmodule/questions',
            answerResponse: 'listeningmodule/AnswerResponse'
        }),
        openNav(){
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav(){
            document.getElementById("mySidenav").style.width = "0";
        },
        showModal() {
            this.dictText= this.selectedquestion.transcript
            this.isShowModal = true;
            this.$root.$emit('bv::show::modal', 'dictionery-Modal')
            this.$refs.timer.pauseTime()
            },
        hideModal() {
            this.isShowModal = false
            this.$refs.timer.resumeTime()
            },
        resetAll(){
            $('.rightAnswer').removeClass('rightAnswer')
            $('.wrongAnswer').removeClass('wrongAnswer')
            this.checkAnswerFlag = false;
            this.userAnswer={
                user_id:this.$ls.get('user').user_id,
                url:'',
                question_id:0,
                option_id:0,
                user_score:'',
                done:false,
                mock_test_id:0
            }
            this.actualAnswer=null,
            this.answerSubmit=false
        },
        redoQuest(){
            this.getQuestionDetail(this.currentQuestion)
        },
       searchQuestion(){
            const key=this.searchKey.trim().toLowerCase()
            const searchedQuestion= this.questionLists.filter((item)=>{
                return item.title.toLowerCase().includes(key)
            });
            this.searchKey = '';
            this.$refs.timer.pauseTime()
            ////console.log(searchedQuestion)
            if(searchedQuestion.length>0){
                if(searchedQuestion.length>1){
                   //console.log(searchedQuestion)
                   this.searchQuestionList=searchedQuestion
                   this.$refs['search-Result'].show()
                }
                else{
                    //console.log(searchedQuestion)
                    return this.getQuestionDetail(searchedQuestion[0].question_id)
                }
            }
            else{
                return this.$alert("No record found with the given title", "Search Result");
            }
        },
        updateSelectQuestionStatus(question,id){
            ////console.log(this.selectedquestion)
            this.selectedquestion = question
            const currectQuestionIndex=this.questionLists.findIndex(element=>
            element.question_id==id)
            this.questionLists[currectQuestionIndex]=this.selectedquestion
            ////console.log(question)
        },
        getQuestionDetail(questionId){
             if(questionId==0) return this.selectedquestion=null
            if(this.$refs['search-Result'].isVisible) this.$refs['search-Result'].hide()
            this.resetAll()
            this.currentQuestion=questionId
            this.userAnswer.question_id = questionId;
            //console.log(questionId)
            this.selectedquestion = this.questionLists.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
            this.modelTitle=this.selectedquestion.title
                if(this.$refs.QuestionResetStatus)
            this.$refs.QuestionResetStatus.resetQuestionDetails(this.selectedquestion,questionId)
                 ////console.log(this.selectedquestion);
            
            //this.questionservice('configlisteningsinglechoice/blobId='+questionId).then(()=>{
                var binary= this.selectedquestion.blb //this.getQuestionList().data.blb;
                var data = binary; //'data:audio/ogg;base64,'+
                this.questionrecording = data;
                this.questionservice('configlisteningsingleoptions/id='+questionId).then(()=>{
                this.answerLists = this.getQuestionList().data
                this.actualAnswer = this.answerLists.filter((item=>{
                    return item.correct_val_ind == "T"
                }))
                this.$nextTick(function(){
            this.$refs.discussionTab.updateQuestionId(questionId)

                this.$refs.timer.setTime(3)
                    });
                });
            //});

            // var binary= this.selectedquestion.question_recording;
            // var data = 'data:audio/ogg;base64,'+binary;
            // this.questionrecording = data;
        },
        toggle: function(todo){
            for(var i = 0; i < this.answerLists.length; i++){
                this.answerLists[i].done = false;
            }
                todo.done = !todo.done
            },
        _arrayBufferToBase64( buffer ) {
            var binary = '';
            var bytes = new Uint8Array( buffer );
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode( bytes[ i ] );
            }
            return window.btoa( binary );
        },
        timerEnd(){
           
               var aud = document.getElementById("questionRec");
               aud.play()
               this.$nextTick(function(){
                   this.title = 'Time'
                this.$refs.timer.setTime(0)
                })
            // aud.onended = function() {
            //     $('.ar-icon.ar-icon__lg').click()
            //     }
        },
        async checkAnswer(){
            var vm = this;
            vm.score_count=0
            vm.correctAnswer= vm.answerLists.filter((item)=>{
                return item.correct_val_ind == "T"
            })[0];
            vm.currentAnswer= vm.answerLists.filter((item)=>{
                return item.done == true
            })[0];

            vm.correctAnswer['done'] = true
            $('#'+vm.correctAnswer['option_id']).addClass('rightAnswer')
            if(vm.currentAnswer['option_id']!= vm.correctAnswer['option_id']){
                $('#'+vm.currentAnswer['option_id']).addClass('wrongAnswer')
            }
            else{ 
                vm.score_count=1
            }
            vm.userAnswer.user_score ='Your Score: '+vm.score_count+'/1';
        },
        async submitanswer() {
            this.currentAnswer= this.answerLists.filter((item)=>{
                return item.done == true
            })
            this.checkAnswerFlag = true;
            if(this.currentAnswer == null | this.currentAnswer =='') {
               //alert(this.currentAnswer)
                return this.answerSubmit = true
            }
            await this.checkAnswer();
            var vm = this;
            var userdata = this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.userAnswer.mock_test_id=0
            //console.log(this.currentAnswer);
            this.userAnswer.option_id = this.currentAnswer.option_id;
            this.userAnswer.url = 'userlisteningsingleanswer/createanswer';
            //console.log(this.userAnswer);
            this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                    vm.userAnswerResponse = this.answerResponse().data
                    //alert('Data has been saved successfully.'); 
                    //vm.resetAll();
                });
                this.answerSubmit= true
        }
    },
    beforeMount(){
        this.questionLists=(this.$ls.get('tblListeningSingleChoice')!=null)?JSON.parse(this.$ls.get('tblListeningSingleChoice')):null;
            console.log(this.questionLists)
            if(this.questionLists!=null){
                var userdata = this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            }
            else{
        this.questionservice('configlisteningsinglechoice/getallquestions').then(()=>{
            this.questionLists = this.getQuestionList().data
            //var userdata = LocalStorageService.getUser()
            var userdata = this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            //console.log(this.questionLists)
        });
    }
    }
}
</script>
<style scoped>

</style>